@import 'variables.scss';

.page.blog-item {
  color: #000;

  ol {
    list-style: decimal;
    padding-left: 20px;
    margin: 16px 0;

    li {
      margin: 8px 0;
    }
  }

  p {
    color: #000;

    &:first-child
    {
      margin-top: 0;
    }
  }

  h2.faq__top {
    width: calc(100% - 32px);
    min-width: calc(100% - 32px);
  }


  .banner-tarif.--3 .banner-tarif__p {
    color: $white-color;
  }

  .memoire-tarifs.--2 .memoire-tarifs__text {
    color: $white-color;
  }

  @media (max-width: 767px) {
    .h2, h2 {
      text-align: left;

      &.--center {
        text-align: left;
      }
    }

    h3 {
      text-align: left;
    }

    .insert-table-here {
      position: fixed;
      bottom: 0;
      width: 100%;
      z-index: 11;
      left: 0;
    }
  }
}

.pld-like-dislike-wrap {
  margin-bottom: 0;
}

.pld-common-wrap {
  border-radius: 4px;
  border: 1px solid $gray3;
  background: $white-color;
  padding: 4px 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 0 !important;
  margin-left: 10px;

  .fa-thumbs-up {
    color: #1BD741;
  }

  .fa-thumbs-down {
    color: #F81111;
  }
}


.blog-list {
  &__item {
    margin-bottom: 24px;
    background: $white-color;
    padding: 16px;
    border-radius: 8px;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    border: 1px solid $white-color;
    transition: all .3s;

    &:hover {
      border: 1px solid $fill2-color;
      filter: drop-shadow(5px 0px 6px rgba(54, 74, 188, 0.09));
    }
  }

  &__pic {
    flex: 0 1 350px;
    margin-right: 16px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    max-height: 200px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__text {
    flex: 1;
  }

  &__inner {
    min-height: 144px;
  }

  &__title {
    margin: 16px 0;
    line-height: 120%;
    font-size: 1.5rem;

    a {
      color: $fill3-color;
      font-size: 1.5rem;
      font-weight: 600;

      &:hover {
        color: $fill6-color;
      }
    }
  }

  &__tags {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 16px 0;

  }

  &__tag {
    color: $main-color;
    font-size: 0.75rem;
    border-radius: 2px;
    border: 1px solid $fill2-color;
    background: $gray1;
    padding: 1px 8px;
    margin-right: 3px;
    margin-bottom: 3px;

    &:hover {
      color: $fill3-color;
      background: #fff
    }
  }

  &__info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-top: 1px solid $fill2-color;
    padding-top: 8px;
    flex: 1;
    flex-wrap: wrap;
  }

  &__bottom {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
  }

  &__btns {
    display: flex;
    flex: 0 1 auto;
    margin-left: 16px;
  }

  &__autor {
    display: flex;
    align-items: center;

    font-size: 0.875rem;
    margin-right: 16px;
  }

  &__date {
    display: flex;
    align-items: center;
    color: $main-color;

    font-size: 0.875rem;

  }

  &__date .ico {
    margin-right: 8px;
    margin-bottom: 0;
  }

  &__date span:last-child {
    color: $fill3-color;
    margin-left: 8px;
  }

  &__name {
    color: $fill6-color;

    text-decoration-line: underline;
    margin-left: 8px;
  }

  &__autorpic {
    flex: 0 1 1.5rem;
    width:  1.5rem;
    height:  1.5rem;
    border-radius: 100%;
    display: flex;
    overflow: hidden;
    margin-right: 8px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__archives {
    border-radius: 8px;
    background: $white-color;
    padding: 16px;
  }

  &__archives-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
  }

  &__btnmore
  {
    height: 42px;
  }

  &__outer {
    height: 317px;
    overflow: hidden;
  }

  &__archive {
    height: 296px;
    overflow: hidden;
  }

  &__btn1 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
  }

  &__h3 {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
     color:#334972;    margin-bottom: 8px;text-transform: uppercase;
  }

  &__archive {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li
      {

        margin: 4px 0 8px 0;
      }
    }

    a {
      color: $main-color;
      position: relative;
      font-size: 14px;
      line-height: 18px;

      &:hover {
        color: $fill6-color;


      }
    }

  }

  &__filter {
    border-radius: 8px;
    background: $white-color;
    padding: 16px;
    margin-bottom: 24px;
  }

  &__search {
    margin-bottom: 24px;
  }

  &__form-wrap {
    align-items: stretch;
    display: flex;
  }

  &__btn {
    background: $fill6-color;
    stroke: $fill2-color;
    border: 1px solid $fill2-color;
    outline: none;
    width: 40px;
    height: 40px;
    min-width: 40px;
    flex: 0 1 40px;
    border-radius: 0 2px 2px 0;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('data:image/svg+xml,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23clip0_445_4711)"><path d="M15.8045 14.8619L11.2545 10.312C12.1359 9.22335 12.6665 7.84001 12.6665 6.33337C12.6665 2.84141 9.82522 9.15527e-05 6.33325 9.15527e-05C2.84128 9.15527e-05 0 2.84138 0 6.33334C0 9.82531 2.84132 12.6666 6.33328 12.6666C7.83992 12.6666 9.22325 12.136 10.3119 11.2546L14.8619 15.8046C14.9919 15.9346 15.1625 15.9999 15.3332 15.9999C15.5039 15.9999 15.6745 15.9346 15.8045 15.8046C16.0652 15.5439 16.0652 15.1226 15.8045 14.8619ZM6.33328 11.3333C3.57597 11.3333 1.33333 9.09066 1.33333 6.33334C1.33333 3.57603 3.57597 1.33339 6.33328 1.33339C9.0906 1.33339 11.3332 3.57603 11.3332 6.33334C11.3332 9.09066 9.09057 11.3333 6.33328 11.3333Z" fill="white"/></g><defs><clipPath id="clip0_445_4711"><rect width="16" height="16" fill="white"/></clipPath></defs></svg>');
    transition: all .3s;

    &:hover {
      background-color: $fill7-color;
    }
  }

  &__input {
    border-radius: 2px 0 0 2px;
    outline: none;
    border: 1px solid $fill2-color;
    background: $white-color;
    padding: 8px 16px;
    flex: 1;
    width: calc(100% - 32px);
    border-right: 0;

    &::placeholder {
      color: $fill2-color;
      font-size: 0.875rem;
    }
  }

  &__categories {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    a {
      background: #F5F9FC;
      border: 1px solid #98BDF1;
      color: #334972;
      font-size: 14px;
      line-height: 18px;
      padding: 4px 8px;
      margin-right: 4px;
      margin-bottom: 4px;

      &:hover {
        background: #98BDF1;
        border: 1px solid #98BDF1;
        color: #334972;
      }
    }
  }

  &__more {
    border-radius: 8px;
    background: $white-color;
    padding: 16px;
    margin-bottom: 24px;
  }

  &__more-item {
    margin: 8px 0;
    padding: 8px 0;    min-width: 100%;
    border-top: 1px solid $fill2-color;

    &:last-child
    {
      border-bottom: 1px solid $fill2-color;
    }

  }

  &__more-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }


  &__more-name {
    margin-bottom: 8px;

    a {
      color: $fill3-color;
      font-family: $font-additional;
      font-weight: 600;

      &:hover {
        color: $fill6-color;
      }

    }

  }

  &__more-info {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &__more-autor {
    flex: 0 1 auto;
    display: flex;
    align-items: center;
    margin-right: 24px;
  }

  &__more-pic {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 1 24px;
    width: 24px;
    height: 24px;
    overflow: hidden;
    border-radius: 100%;
    margin-right: 8px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__more-detail {
    color: $main-color;
    font-size: 0.875rem;
    text-decoration: underline;
  }

  &__more-date {
    flex: 0 1 auto;
    color: $main-color;
    font-size: 0.875rem;

    .ico {
      margin-right: 8px
    }
  }

  &__more-btn .simple-link {

    display: block;
    margin: auto;
    text-align: center;
    margin-top: 12px;

  }

  @media (max-width: 1280px) {
    &__info {
      align-items: flex-start;
      flex-direction: column;
    }
    &__autor {
      margin-right: 0;
      margin-bottom: 4px;
    }
    &__btns {
      margin-left: 16px;
    }
    &__pic {
      flex: 0 1 256px;
    }
    &__title {
      margin: 0 0 16px 0;
    }

  }


  @media (max-width: 1024px) {
    &__title a {
      font-size: 1.25rem;
    }
  }


  @media (max-width: 767px) {

    &__item {
      flex-direction: column;
      border: 1px solid #98bdf1;
      filter: drop-shadow(5px 0px 6px rgba(54, 74, 188, 0.09));
    }
    &__pic {
      flex: 0 1 100%;
      margin-left: -16px;
      margin-right: -16px;
      margin-top: -16px;
      margin-bottom: 24px;
    }
    &__inner {
      min-height: 80px;
    }
    &__info {
      flex-direction: row;
      flex-wrap: wrap;
    }
    &__autor {
      margin-right: 24px;
      margin-bottom: 0;
    }
  }


  @media (max-width: 580px) {
    &__bottom {

      flex-direction: column;
    }
    &__info {
      flex-direction: row;
      flex-wrap: wrap;
      flex: 1;
      width: 100%;
    }
    &__btns {
      margin-left: 0rem;
      margin-top: 16px;
      width: 100%;

      .btn {
        width: 100%;
        color: $white-color;
        background: $fill4-color;
        border: 1px solid $fill4-color;

        &:hover {
          background: $fill5-color;
          border: 1px solid $fill5-color;
        }

        &.--ico-eye.--border:before {
          background-color: $white-color;
        }

      }
    }
  }

}




.blog-desc {
  margin: 40px 0 80px 0;

  a {
    color: $fill6-color;

    &:hover {
      color: $main-color;
    }
  }

  @media (max-width: 767px) {
    & {
      margin: 24px 0 48px 0;
    }
  }
}


.share {
  position: relative;
  display: flex;

  .share-block {
    position: absolute;
    bottom: 48px;
    padding: 12px;
    background: $white-color;
    width: 432px;
    left: 0;
    transform: scaleY(0);
    transition: all .3s;

    &.active {
      transform: scaleY(1);
    }

    &:before {
      content: '';
      position: absolute; /* Абсолютное позиционирование */
      left: 16px;
      bottom: -19px;
      border: 10px solid transparent; /* Прозрачные границы */
      border-top: 10px solid $white-color; /* Добавляем треугольник */
    }

  }

  .share-block__item {
    display: none
  }

  .share-block__top {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 8px;
    margin-bottom: 16px;
    border-bottom: 3px solid #a0a0a0;
  }

  .share-block__copy {
    margin-top: 8px;
  }

  .share-block__copy-input {
    border-radius: 2px;
    border: 1px solid $fill2-color;
    background: $white-color;
    padding: 6px 8px;
    color: $main-color;
    font-size: 0.75rem;
    line-height: 200%;
    width: 192px;
  }

  .share-block__copy-button {
    color: $white-color;
    text-align: center;
    font-family: $font-additional;
    font-weight: 500;
    text-transform: uppercase;
    background: $fill6-color;
    padding: 10px 16px;
    border: none;
    outline: none;
    border-radius: 2px;
    transition: all .3s;
    cursor: pointer;

    &:hover {
      background: $main-color;
    }
  }

  .share-block__body {
    min-height: 64px;
  }

  .share-block__name {
    padding: 0 8px;
    position: relative;
    color: #a0a0a0;
    cursor: pointer;
    transition: all .3s;

    &.--2:before {
      background-image: url('data:image/svg+xml,<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M16 2H8a3 3 0 0 0-3 3v16a1 1 0 0 0 .5.87 1 1 0 0 0 1 0l5.5-3.18 5.5 3.18a1 1 0 0 0 .5.13 1 1 0 0 0 .5-.13A1 1 0 0 0 19 21V5a3 3 0 0 0-3-3Zm1 17.27-4.5-2.6a1 1 0 0 0-1 0L7 19.27V5a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1Z" fill="%23327be7" class="fill-6563ff"></path></svg>');
    }

    &.--3:before {
      background-image: url('data:image/svg+xml,<svg viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg"><path d="M528.1 171.5 382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM388.6 312.3l23.7 138.4L288 385.4l-124.3 65.3 23.7-138.4-100.6-98 139-20.2 62.2-126 62.2 126 139 20.2-100.6 98z" fill="%23327be7" class="fill-000000"></path></svg>');
    }

    &:before {
      content: "";
      background-image: url('data:image/svg+xml,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23clip0_421_2190)"><path d="M12.6875 10.375C11.7612 10.375 10.945 10.8307 10.4323 11.5236L5.99969 9.25387C6.07328 9.00303 6.125 8.74306 6.125 8.46875C6.125 8.09669 6.04872 7.74297 5.91694 7.41762L10.5558 4.62612C11.0721 5.232 11.8309 5.625 12.6875 5.625C14.2384 5.625 15.5 4.36341 15.5 2.8125C15.5 1.26159 14.2384 0 12.6875 0C11.1366 0 9.875 1.26159 9.875 2.8125C9.875 3.16991 9.94859 3.50894 10.0707 3.82369L5.41797 6.62337C4.90216 6.0355 4.15428 5.65625 3.3125 5.65625C1.76159 5.65625 0.5 6.91784 0.5 8.46875C0.5 10.0197 1.76159 11.2812 3.3125 11.2812C4.25406 11.2812 5.08409 10.8122 5.59484 10.0998L10.0128 12.3622C9.93147 12.6248 9.875 12.8984 9.875 13.1875C9.875 14.7384 11.1366 16 12.6875 16C14.2384 16 15.5 14.7384 15.5 13.1875C15.5 11.6366 14.2384 10.375 12.6875 10.375Z" fill="%23327BE7"/></g><defs><clipPath id="clip0_421_2190"><rect width="16" height="16" fill="white"/></clipPath></defs></svg>');
      width: 16px;
      background-size: contain;
      background-repeat: no-repeat;
      height: 16px;
      display: inline-block;
      margin-bottom: -3px;
      margin-right: 4px;
      filter: grayscale(1) brightness(1.5);
      transition: all .3s;
    }

    &.active:before {
      filter: grayscale(0) brightness(1);
      transition: all .3s;
    }

    &.active {
      color: $fill6-color;
    }

    &:after {
      content: "";
      width: 0%;
      position: absolute;
      left: 0;
      bottom: -11px;
      height: 3px;
      background: $fill6-color;
    }

    &.active:after {
      width: 100%;
    }
  }

  .btn.--ico-cite.js-save-page {
    margin-top: 4px;
    display: inline-block;
    text-align: center;
  }

  @media (max-width: 1024px) {

    &:before {
      left: 98px;
    }
  }

  @media (max-width: 767px) {

    &:before {
      left: 11.16px;
    }
    .btn-white {
      margin: 0 12px;
    }

    .share-block {
      left: -140px;

      :before {
        left: 49%;
      }
    }


  }

  @media (max-width: 580px) {
    .share-block {
      left: -52px;
      width: 256px;
      box-shadow: 0 2px 10px #ccc;
      border-radius: 8px;
      bottom: 66px;
    }
    .share-block:before {
      left: 69px;
    }
    .share-block__copy-button {
      width: 100%;
    }
    .share-block__copy-input {

      width: calc(100% - 16px);
      margin-bottom: 4px;
    }
  }
}


/*.blog-table {
  filter: drop-shadow(5px 0px 6px rgba(54, 74, 188, 0.09));
  background: $white-color;
  border-radius: 8px;
  overflow: hidden;
  margin:  0;
  border: none;
  outline: none;
  border-spacing: 0px;
  border-collapse: unset;


  &.--many
  {
    .blog-table__row
    {
      display: flex;
    }
    .blog-table__td {
      flex: 1;
    }
    .blog-table__td:first-child p {
      color: #334972;
    }

    @media (max-width: 767px) {
      .blog-table__row
      {
        display: flex;
        flex-direction: row;
        width: 100%;
      }
     .blog-table__td {
        flex: 1;
        min-width: 1px;
       border-right: 1px solid #adcdfc;        padding: 4px;
      }
    }
  }

  &__row {
    display: grid;
    grid-template-columns: 1fr 2.3fr;
    border-bottom: 1px solid $fill2-color;

    &:last-child {
      border-bottom: 0px solid $fill2-color;
    }

    p {
      margin: 0;
    }

    .blog-table__td {
      padding: 24px;

      p {
        margin-bottom: 16px;

        &:last-child {
          margin-bottom: 0px;
        }
      }

      &:first-child {
        border-right: 1px solid $fill2-color;
        color: $fill6-color;
        font-weight: 500;

        p {
          color: $fill6-color;
        }

      }
    }

  }


  &__row.--title {
    .blog-table__td {
      color: #fff !important;
      background: #2874fc;
      padding: 8px 24px;

      p {
        margin: 0;
        color: #fff !important;
      }

      &:first-child {
        border-right: 1px solid $white-color;
        font-weight: normal;
        color: #fff !important;
      }
    }

  }


  @media (max-width: 580px) {
    &__row {
      grid-template-columns: 1fr  1fr;
    }
    &__row.--title .blog-table__td {
      color: #fff !important;
      background: #2874fc;
      padding: 8px;
      width: calc(100% - 17px);
    }

    &__row.--title .blog-table__td:first-child {
      border-right: 1px solid $white-color;
      padding: 8px;
      border-bottom: 0px solid $white-color;
      width: calc(100% - 17px);
    }
    &__row .blog-table__td {
      padding: 8px;
      flex: 0 1 calc(100% - 17px);
      width: calc(100% - 17px);
      min-width: calc(100% - 17px);
      word-break: break-all;
    }
    &__row .blog-table__td:first-child {
      padding-bottom: 0;
    }
  }

}*/


.blog-text {
  margin: 8px 0;
  display: inline-block;
  width: 100%;
  position: relative;

  p:last-child {
    margin-bottom: 0;
  }

  table:last-child {
    margin-bottom: 0;
  }

  h2 {
    margin-bottom: 16px;

    &:nth-child(2) {
      margin-top: 0 !important;
    }
  }
}

.blog-autor {
  margin: 24px 0 64px 0;

  &__row {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__left {
    display: flex;
    align-items: center;

    .btn-white {
      margin-right: 24px;

      &.active {
        border: 1px solid $fill4-color;
        background: $fill4-color;
        color: $white-color;

        &:before {
          filter: brightness(111);
        }
      }
    }
  }

  &__right {
    font-family: $font-additional;
    display: flex;
    align-items: center;
    flex: 0 1 auto;
  }

  &__btns {
    margin-left: 24px;

    .btn-like {
      margin-left: 8px
    }
  }

  &__wrap {
    border-radius: 8px;
    background: $white-color;
    padding: 32px;
  }

  &__info {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 16px;
  }

  &__info-left {
    align-items: center;
    display: flex;
    flex: 0 1 auto;
  }

  &__info-pic {
    width: 64px;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    overflow: hidden;
    margin-right: 24px;

    img {
      object-fit: cover;
      object-position: center top;
      width: 100%;
      height: 100%;
    }
  }

  &__info-text {
    display: flex;
    flex-direction: column;
    font-size: 0.875rem;
    align-items: flex-start;

    a {
      color: $fill6-color;
      border-bottom: 2px solid;

      &:hover {
        border-bottom: 2px solid transparent;
      }
    }

    b {
      font-size: 1.5rem;
      color: $fill6-color;

      margin-top: 6px;
    }
  }

  &__info-right {
    a {
      color: $fill3-color;

      &:hover {
        color: $fill6-color;
      }
    }
  }

  &__text {
    color: $fill3-color;
  }

  @media (max-width: 1024px) {
    &__row {
      flex-direction: column;
    }
    &__left {
      margin-bottom: 16px;
    }
    & {
      margin: 16px 0 32px 0;
    }
  }


  @media (max-width: 580px) {

    & {
      margin-top: 16px;
    }

    &__left .btn-white {
      margin-right: 0;
      margin-bottom: 16px;
    }

    &__right {
      flex-direction: column;
      text-align: center;
      margin-top: 16px;
      margin-bottom: 16px;
    }
    &__btns {
      margin-left: 0;
      margin-top: 12px;
    }
    &__wrap {
      padding: 16px;
    }
    &__info-text {
      flex: 1;
      font-size: 0.875rem;
    }
    &__info-pic {

      margin-right: 16px;
    }
    &__left {
      margin-bottom: -16px;
    }
    &__info-text b {
      font-size: 1.25rem;
      margin-top: 4px;
    }
    &__info-right {
      margin-top: 16px;
    }
    &__info-right a {
      color: $fill6-color;
    }
    &__info {
      margin-bottom: 16px;
      flex-direction: column;
    }

  }
}


.comments {


  &__row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 16px;
    margin-bottom: 20px;
    border-bottom: 1px solid $fill2-color;
  }

  .comment {
    padding: 24px;
    margin-bottom: 24px;
    background: $white-color;
    border-radius: 8px;
  }


  .children {
    padding: 0px;
    padding-left: 32px;
    margin: 0;
    margin-top: 16px;

    .comment {
      margin-bottom: 0px;
    }
  }

  &__autor {
    color: $fill3-color;
    font-weight: 600;
    font-family: $font-additional;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &:before {
      content: "";
      background-image: url('data:image/svg+xml,<svg width="26" height="32" viewBox="0 0 26 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 5.33333C0 2.38782 2.53056 0 5.65217 0H20.3478C23.4694 0 26 2.38781 26 5.33333V26.6667C26 29.6122 23.4694 32 20.3478 32H5.65217C2.53056 32 0 29.6122 0 26.6667V5.33333Z" fill="%23327BE7"/><path d="M13 4C9.51067 4 6.67188 6.8388 6.67188 10.3281C6.67188 13.8175 9.51067 16.6562 13 16.6562C16.4893 16.6562 19.3281 13.8175 19.3281 10.3281C19.3281 6.8388 16.4893 4 13 4Z" fill="white"/><path d="M20.8734 20.7904C19.1409 19.0313 16.8442 18.0625 14.4062 18.0625H11.5938C9.15588 18.0625 6.85909 19.0313 5.12659 20.7904C3.40258 22.5409 2.45312 24.8515 2.45312 27.2969C2.45312 27.6852 2.76794 28 3.15625 28H22.8438C23.2321 28 23.5469 27.6852 23.5469 27.2969C23.5469 24.8515 22.5974 22.5409 20.8734 20.7904Z" fill="white"/></svg>');
      width: 1.625rem;
      height: 2rem;
      margin-right: 10px;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  &__btns {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 24px;
  }

  @media (max-width: 580px) {

    .comment {
      padding: 16px;
    }
    .children .comment {
      margin-bottom: 0px;
      padding: 0;
      padding-top: 16px;
    }
    .comments__row {
      flex-direction: column;
    }
    .comments__date {
      margin-top: 8px;
    }
  }

}


.notice-block {
  border-left: 4px solid $fill6-color;
  background: $white-color;
  padding: 24px;
  padding-bottom: 8px;

  &.--Blue2 {

    .notice-block__title {
      &:before {
        background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.5 6.375V0H4.125C3.50156 0 3 0.501562 3 1.125V22.875C3 23.4984 3.50156 24 4.125 24H19.875C20.4984 24 21 23.4984 21 22.875V7.5H14.625C14.0063 7.5 13.5 6.99375 13.5 6.375ZM16.5 17.4375C16.5 17.7469 16.2469 18 15.9375 18H8.0625C7.75313 18 7.5 17.7469 7.5 17.4375V17.0625C7.5 16.7531 7.75313 16.5 8.0625 16.5H15.9375C16.2469 16.5 16.5 16.7531 16.5 17.0625V17.4375ZM16.5 14.4375C16.5 14.7469 16.2469 15 15.9375 15H8.0625C7.75313 15 7.5 14.7469 7.5 14.4375V14.0625C7.5 13.7531 7.75313 13.5 8.0625 13.5H15.9375C16.2469 13.5 16.5 13.7531 16.5 14.0625V14.4375ZM16.5 11.0625V11.4375C16.5 11.7469 16.2469 12 15.9375 12H8.0625C7.75313 12 7.5 11.7469 7.5 11.4375V11.0625C7.5 10.7531 7.75313 10.5 8.0625 10.5H15.9375C16.2469 10.5 16.5 10.7531 16.5 11.0625ZM21 5.71406V6H15V0H15.2859C15.5859 0 15.8719 0.117188 16.0828 0.328125L20.6719 4.92188C20.8828 5.13281 21 5.41875 21 5.71406Z" fill="%23327be7"/></svg>');
      }
    }
  }


  &.--Red {
    border-left: 4px solid #F81111;

    .notice-block__title {
      &:before {
        background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23clip0_1116_4385)"><path d="M23.625 12C23.625 18.4217 18.4199 23.625 12 23.625C5.58014 23.625 0.375 18.4217 0.375 12C0.375 5.58202 5.58014 0.375 12 0.375C18.4199 0.375 23.625 5.58202 23.625 12ZM12 14.3438C10.8091 14.3438 9.84375 15.3091 9.84375 16.5C9.84375 17.6909 10.8091 18.6562 12 18.6562C13.1909 18.6562 14.1562 17.6909 14.1562 16.5C14.1562 15.3091 13.1909 14.3438 12 14.3438ZM9.95283 6.59316L10.3005 12.9682C10.3168 13.2665 10.5635 13.5 10.8622 13.5H13.1378C13.4365 13.5 13.6832 13.2665 13.6995 12.9682L14.0472 6.59316C14.0648 6.27094 13.8082 6 13.4855 6H10.5144C10.1917 6 9.93525 6.27094 9.95283 6.59316Z" fill="%23F81111"/></g><defs><clipPath id="clip0_1116_4385"><rect width="24" height="24" fill="white"/></clipPath></defs></svg>');

      }
    }
  }

  &.--Green {
    border-left: 4px solid #1BD741;

    .notice-block__title {
      &:before {
        background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.5 6.375V0H4.125C3.50156 0 3 0.501562 3 1.125V22.875C3 23.4984 3.50156 24 4.125 24H19.875C20.4984 24 21 23.4984 21 22.875V7.5H14.625C14.0063 7.5 13.5 6.99375 13.5 6.375ZM16.5 17.4375C16.5 17.7469 16.2469 18 15.9375 18H8.0625C7.75313 18 7.5 17.7469 7.5 17.4375V17.0625C7.5 16.7531 7.75313 16.5 8.0625 16.5H15.9375C16.2469 16.5 16.5 16.7531 16.5 17.0625V17.4375ZM16.5 14.4375C16.5 14.7469 16.2469 15 15.9375 15H8.0625C7.75313 15 7.5 14.7469 7.5 14.4375V14.0625C7.5 13.7531 7.75313 13.5 8.0625 13.5H15.9375C16.2469 13.5 16.5 13.7531 16.5 14.0625V14.4375ZM16.5 11.0625V11.4375C16.5 11.7469 16.2469 12 15.9375 12H8.0625C7.75313 12 7.5 11.7469 7.5 11.4375V11.0625C7.5 10.7531 7.75313 10.5 8.0625 10.5H15.9375C16.2469 10.5 16.5 10.7531 16.5 11.0625ZM21 5.71406V6H15V0H15.2859C15.5859 0 15.8719 0.117188 16.0828 0.328125L20.6719 4.92188C20.8828 5.13281 21 5.41875 21 5.71406Z" fill="%231BD741"/></svg>');
      }
    }
  }


  &__title {
    color: $fill3-color;
    font-family: $font-additional;
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 16px;
    display: flex;
    align-items: center;

    h2, h3 {
      margin: 0;
    }


    &:before {
      content: "";
      width: 1.5rem;
      height: 1.5rem;
      display: inline-block;
      margin-bottom: -3px;
      margin-right: 12px;
      background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21.75 12H18V9C18 7.34531 19.3453 6 21 6H21.375C21.9984 6 22.5 5.49844 22.5 4.875V2.625C22.5 2.00156 21.9984 1.5 21.375 1.5H21C16.8563 1.5 13.5 4.85625 13.5 9V20.25C13.5 21.4922 14.5078 22.5 15.75 22.5H21.75C22.9922 22.5 24 21.4922 24 20.25V14.25C24 13.0078 22.9922 12 21.75 12ZM8.25 12H4.5V9C4.5 7.34531 5.84531 6 7.5 6H7.875C8.49844 6 9 5.49844 9 4.875V2.625C9 2.00156 8.49844 1.5 7.875 1.5H7.5C3.35625 1.5 0 4.85625 0 9V20.25C0 21.4922 1.00781 22.5 2.25 22.5H8.25C9.49219 22.5 10.5 21.4922 10.5 20.25V14.25C10.5 13.0078 9.49219 12 8.25 12Z" fill="%23327BE7"/></svg>');
      min-width: 24px;
    }
  }

  &__text {


    ul {
      padding: 0;
      list-style: none;
      font-style: italic;

      li {
        margin: 8px 0;
        padding-left: 16px;
        position: relative;

        &:before {
          content: "";
          width: 4px;
          height: 4px;
          background: $main-color;
          position: absolute;
          left: 0;
          top: 10px;
          border-radius: 100%;
        }
      }
    }
  }

  @media (max-width: 767px) {
    &__title {
      font-size: 1.25rem;
    }
  }

  @media (max-width: 580px) {
    & {
      padding: 16px;
      padding-bottom: 8px;
    }
  }
}


.blog-exemples {

  h2 {
    margin-bottom: 24px;
  }

  .blog-exemples__slider {
    max-width: 885px;
  }

  .blog-exemples__slide {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
    }

  }

  @media (max-width: 1024px) {
    &__slider {
      max-width: 100%;
    }
  }
  @media (max-width: 767px) {
    &__slider {
      margin: 0 32px;
    }
  }
}


.autor-comment {
  border-left: 4px solid $fill6-color;
  background: $white-color;
  padding: 24px;


  &__autor {
    display: flex;
    margin-bottom: 16px;
    position: relative;

    &:before {
      content: "";
      width: 24px;
      z-index: 3;
      left: -8px;
      top: -8px;
      height: 24px;
      position: absolute;
      background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21.75 12H18V9C18 7.34531 19.3453 6 21 6H21.375C21.9984 6 22.5 5.49844 22.5 4.875V2.625C22.5 2.00156 21.9984 1.5 21.375 1.5H21C16.8563 1.5 13.5 4.85625 13.5 9V20.25C13.5 21.4922 14.5078 22.5 15.75 22.5H21.75C22.9922 22.5 24 21.4922 24 20.25V14.25C24 13.0078 22.9922 12 21.75 12ZM8.25 12H4.5V9C4.5 7.34531 5.84531 6 7.5 6H7.875C8.49844 6 9 5.49844 9 4.875V2.625C9 2.00156 8.49844 1.5 7.875 1.5H7.5C3.35625 1.5 0 4.85625 0 9V20.25C0 21.4922 1.00781 22.5 2.25 22.5H8.25C9.49219 22.5 10.5 21.4922 10.5 20.25V14.25C10.5 13.0078 9.49219 12 8.25 12Z" fill="%23327BE7"/></svg>');

    }
  }

  &__name a {
    color: $fill6-color;
    font-size: 1.5rem;
    font-weight: 700;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  &__text {

    font-style: italic;
  }

  &__about {
    font-size: 1rem;
    line-height: 120%;
    margin-bottom: 8px;
  }

  &__pic {
    display: flex;
    flex: 0 1 64px;
    width: 64px;
    height: 64px;
    min-width: 64px;
    margin-right: 16px;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 100%;
    overflow: hidden;

    img {
      width: 100%;
      object-fit: cover;
      height: 100%;
    }
  }

  @media (max-width: 580px) {
    & {
      padding: 16px;
    }
    &__autor {
      margin-bottom: 8px;
    }
  }
}