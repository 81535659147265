@import 'variables.scss';

.memoire-tarifs {
  background-image: url(../img/p22.png);
  background-color: $fill6-color;
  background-repeat: no-repeat;
  background-position: left bottom;
  padding: 16px 32px;
  border-radius: 8px;
  overflow: hidden;
  padding-left: 202px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-size: auto 80%;
  min-height: 100px;


  &.--2 {

    padding-left: 208px;
    background-size: 208px;

    .memoire-tarifs__btns {
      flex: 0 1 auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      text-align: right;

      .btn.--border {
        margin-right: 0rem;
        margin-bottom: 8px;
      }
    }

    .memoire-tarifs__text {
      padding-right: 16px;
    }
  }

  &__text {
    flex: 1;
    color: $white-color;
    font-family: $font-additional;
    font-size: 1.25rem;
    margin-right: 8px;
    font-weight: 500;
    margin-bottom: 0rem;


    span {
      display: block;
      padding-top: 8px;
    }

    &:before {
      background-image: url(../img/hand.svg);
      width: 20px;
      height: 20px;
      display: inline-block;
      margin-right: 6px;
      margin-bottom: 2.5px;
    }
  }

  &__btns {
    flex: 0 1 auto;
    display: flex;

    .btn.--border {
      background: $white-color;
      margin-right: 16px;

      &:hover {
        background: $fill5-color;
      }
    }
  }


  @media (max-width: 1024px) {
    &.--2 .memoire-tarifs__btns {
      justify-content: center;
      align-items: flex-start;
      text-align: center;
    }
    & {
      flex-direction: column;
      background-size: 240px;
      align-items: flex-start;
      padding-left: 250px;
    }
    &__btns {
      margin-top: 8px;
    }
  }


  @media (max-width: 767px) {

    & {
      flex-direction: column;
      align-items: flex-start;
      position: relative;
      padding: 30px 16px;
      background-size: 144px;
      background-image: none !important;
    }
    .btn.--ico-pen {
      padding: 8px 8px;
    }

    &.--2 .memoire-tarifs__text {
      padding-right: 0rem;
      width: 100%;
      text-align: center;
    }
    &.--2 {
      padding: 28px 30px;
    }
    &.--2 .memoire-tarifs__btns {
      justify-content: center;
      align-items: center;
    }

    &__text {
      text-align: center;
      margin-bottom: 18px;
    }

    &__btns {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      margin-top: 0;
    }
    &__btns .btn.--border {
      background: $white-color;
      margin-right: 0rem;
      margin-bottom: 8px;
    }
  }

  @media (max-width: 480px) {

    &emoire-tarifs.--2 {
      padding: 20px;
      background-size: 144px;

      .btn {
        font-size:0.875rem;
        padding: 6px 12px;
      }

      .memoire-tarifs__btns .btn.--border {

        margin-bottom: 8px;
      }
    }
  }

}


.banner-pic {
  padding: 16px 24px;
  background-color: $fill6-color;
  border-radius: 8px;
  display: flex;
  align-items: center;


  &__pic {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex: 0 1 224px;
    width: 224px;
    margin-bottom: -16px;
    margin-left: -24px;
    margin-right: 16px;

    img {
      max-width: 100%;
    }
  }

  &__info {
    flex: 1;
    color: $white-color;
    font-size:1.25rem;
    font-weight: 500;
    margin-bottom: 0rem;
    display: flex;
    align-items: center;
  }

  &__text {
    color: #fff !important;
    font-size: 1.25rem;
    font-weight: 500;
    margin-bottom: 0;
    flex: 1;
    padding-right: 32px;
    border-bottom: 0 !important;

    span {
      padding-top: 8px;
      display: block;
    }
  }

  &__btns {
    flex: 0 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    text-align: right;

    .btn {
      color: $white-color;
      text-decoration: none;
      min-width: 100px;
    }

    .btn.--border {
      color: $fill4-color;
      margin-right: 0rem;
      margin-bottom: 8px;
      background-color: $white-color;
      text-decoration: none;

      &:hover {
        background: $fill5-color;
        border: 1px solid $fill5-color;
        color: $white-color;
      }
    }
  }

  @media (max-width: 767px) {
    &__pic {
      display: none;
    }
    &__info {
      flex-direction: column;
    }
    &__text {
      padding-right: 0;
      text-align: center !important;
    }
    &__btns {
      justify-content: center;
      align-items: center;
      text-align: center;
      margin-top: 16px;
    }
  }
}

.banner-tarif {
  background: $fill6-color;
  border-radius: 8px;
  display: flex;
  align-items: stretch;
  width: 100%;

  &.--2 {

    .banner-tarif__pic {
      flex: 0 1 180px;
      margin-right: 80px;
      margin-left: 16px;

      img {
        width: 110%;
        margin-right: -16px;
      }
    }

    .banner-tarif__text {
      padding-right: 16px;
      padding-top: 16px;
      padding-bottom: 16px;
    }

    .banner-tarif__bnts {
      margin-top: 16px;
      margin-bottom: 0;
    }

    &.--woman {
      .banner-tarif__pic {
        margin-right: 32px;
      }

      .banner-tarif__bnts {
        margin-top: 0rem !important;
      }

      img {
        width: auto !important;
        max-height: 192px;
      }
    }
  }

  &.--3 {
    margin-bottom: 0rem;

    &.--page-blog.mb-64.mt-64 {
      margin-top: 40px;
      margin-bottom: 40px;
    }

    .banner-tarif__bnts {
      margin-top: 20px;
      margin-bottom: 20px;
      width: 100%;
      display: flex;
      flex-direction: row;

      .btn.--border {
        margin-right: 8px;
      }

    }
  }


  &.--5 {
    .banner-tarif__pic {
      margin-right: 32px;
      margin-left: 16px;
    }

    .banner-tarif__text {

      padding-right: 30px;
      padding-top: 30px;
      padding-bottom: 30px;
    }

    .banner-tarif__bnts {

      margin-top: 0;
      margin-bottom: 0;
    }

  }

  &__pic {
    flex: 0 1 196px;
    display: flex;

    align-items: self-end;
    justify-content: center;
    margin-right: 48px;
    margin-left: 72px;

    img {
      width: 100%;
      max-width: 194px;
    }
  }

  &__text {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 34px;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  &__p, h3 {
    color: $white-color;
    font-size:1.25rem;
    font-weight: 500;
    margin-bottom: 8px;
  }


  &__bnts {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 12px;
    margin-bottom: 0rem;
    width: auto;
    margin-right: auto;

    .btn.--border {
      background: $white-color;
      margin-bottom: 0rem;
      margin-right: 8px;

      &:hover {
        background: $fill4-color;
      }
    }
  }


  &__pay {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 16px;
    flex-wrap: wrap;

    img {
      margin-right: 8px;
      max-width: 442px;
      width: 100%;
      height: auto;
      &.banner-tarif__pay-mobile
      {
        display:none;
      }
    }
  }

  @media (max-width: 1280px) {

    &__p {
      margin-bottom: 10px;

      &:first-child {
        margin-bottom: 16px;
      }
    }
    &__text {
      padding-right: 16px;
    }
    &__pic {
      flex: 0 1 210px;
      margin-right: 16px;
      margin-left: 24px;
    }
    &__bnts {
      margin-top: 20px;
    }

    &.--3 .banner-tarif__p:first-child {
      margin-bottom: 0rem;
    }
    &.--3 .banner-tarif__bnts {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 1024px) {
    &.--2.--mobile {
      display: flex;
    }
  }


  @media (max-width: 767px) {
    & {
      position: relative;
    }
    &.--5 .banner-tarif__bnts {
      margin-top: 0rem;
      margin-bottom: 0;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
    &__pic {
      display: none;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100px;
      margin-left: 0;
    }
    &__text {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 16px;
      position: relative;
      z-index: 4;
    }
    &__bnts {
      margin-top: 11px;
      margin-bottom: 0rem;
      align-items: center;
      width: 100%;
      margin-left: auto;
      margin-right: 0;
      flex-direction: column;
    }
    &__bnts .btn.--border {
      margin-right: 0rem;

      margin-bottom: 8px;
      padding: 8px 8px;
    }
    &__bnts .btn {
      padding: 8px 8px;
      min-width: 140px;
    }
    &__p {
      text-align: center;
    }
    &__pay {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      max-width: 100%;
      margin: auto;
      margin-top: 16px;

      img {
        margin-right: 0;
        height: auto;
        width: 100%;
        max-width: 242px;
        margin-left: 4px;
        margin-bottom: 5px;

        &.banner-tarif__pay-desc
        { display:none}
        &.banner-tarif__pay-mobile
        { display:block}

      }

    }


    &.--2 .banner-tarif__text {
      padding: 16px;
    }
    &.--2.--mobile {
      position: relative;
    }
    &.--2 .banner-tarif__pic {
      display: none;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 190px;
      margin-left: 0;
    }

    &.--2 .banner-tarif__p {
      text-align: center;
    }
    &.--2 .banner-tarif__bnts {
      justify-content: center;
      align-items: center;
      position: relative;
      z-index: 2;

      .btn.--border {
        background: $white-color;

      }
    }

    &.--3 .banner-tarif__p:first-child {

      text-align: center;
    }
    &.--3 .banner-tarif__bnts {
      margin-top: 16px;
      margin-bottom: 0rem;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
    &.--3 .banner-tarif__pic {
      width: 148px;
    }

  }

  @media (max-width: 640px) {

    &.--2 .banner-tarif__bnts {
      flex-direction: column;

      .btn.--border {
        margin-right: 0;
        margin-bottom: 8px;
      }
    }
  }


  @media (max-width: 580px) {
    .banner-tarif__text {
      padding-top: 26px;
    }
    &.--3 .banner-tarif__bnts {
      margin-top: 16px;
      margin-bottom: 0rem;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
    &.--3 .banner-tarif__bnts .btn.--border {
      margin-right: 0;
      margin-bottom: 6.5px;
    }
  }


  @media (max-width: 480px) {
    &.--5 {
      .banner-tarif__bnts {
        margin-top: 0rem;
        margin-bottom: 0;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        .btn {
          font-size: 0.875rem !important;
          padding: 4px 8px !important;
          width: auto;
        }

        .btn.--border {
          margin-bottom: 12px;
          margin-right: 0;
        }
      }

      &__pic {
        width: 132px;
      }

      &__text {
        padding: 16px;
      }
    }

    &__bnts .btn {
      padding: 8px 8px;
      width: auto;
      flex: 1;
    }
    &.--2 .banner-tarif__bnts .btn.--border {
      margin-right: 0;
      padding: 8px 16px;
      width: auto;
    }
  }

}


