$main-color: #334972;
$fill1-color: #64a7ff;
$fill2-color: #98bdf1;
$fill3-color: #121d31;
$fill4-color: #f86011;
$fill5-color: #e35004;
$fill6-color: #327be7;
$fill7-color: #002f87;
$fill8-color: #ddebff;
$fill9-color: #4ABE71;
$fill10-color: #309f55;
$fill11-color: #4967a0;
$fill12-color: #d6e5fa;
$fill13-color: #EB9481;
$fill14-color: #7AD38C;
$white-color: #fff;
$gray1: #f5f9fc;
$gray2: #FCFDFE;
$gray3: #eaecf0;
$font-additional: "Montserrat", sans-serif;


@mixin btn-icon-style1() {
  content: "";
  background-repeat: no-repeat;
  background-size: contain;
  height: 1rem;
  width: 1rem;
  display: inline-block;
  margin-right: 6px;
  margin-bottom: -3px;
}

@mixin border-shadow1() {
  border-radius: 8px;
  border: 1px solid $fill2-color;
  box-shadow: 5px 0px 6px 0px rgb(54 74 188 / 9%);
}